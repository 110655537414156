<template>
  <div
    v-if="loading"
    class="border rounded-2xl overflow-hidden shadow-lg lg:h-72 animate-pulse"
  >
    <div class="w-full h-40 bg-gray-300"></div>
    <div class="p-4">
      <div class="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
      <div class="h-4 bg-gray-300 rounded w-1/2"></div>
    </div>
  </div>
  <div
    v-if="!loading"
    class="border group rounded-lg overflow-hidden shadow-lg h-[17rem] hover:cursor-pointer"
    @click="goToArticle(article.link)"
                  v-motion="{ initial: { opacity: 0, scale: 0.8 }, visible: { opacity: 1, scale: 1 } }"
        transition="{ delay: index * 0.2, duration: 0.8 }"
  >
    <div class="relative">
      <img
        :src="article.featured_image_src"
        :alt="'article element'"
        class="w-full h-40 object-cover transition duration-300 group-hover:scale-110"
      />
    </div>

    <div class="p-4 h-32">
      <div class="flex justify-between">
        <h3 v-html="article.title.rendered" class="font-semibold text-sm text-left">
        </h3>
      </div>
      <div class="flex justify-between">
        <div>
          <p class="text-gray-600 text-left text-sm">
            <i class="fas fa-calendar-day text-blue"></i>
            {{ formatDate(article.date) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    article: Object,
  },
  methods: {
    formatDate(value) {
      const date = new Date(value);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Example: 7-11-2024
    },
    goToArticle(link) {
      window.open(link, "_blank");
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    // Simulate data loading
    setTimeout(() => {
      this.loading = false;
    }, 2000); // Adjust timing as needed
  },
};
</script>
