import { createApp } from 'vue';
import App from './App.vue';
import './assets/style.css';
import router from './router';
import store from './store';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createI18n } from 'vue-i18n';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import IntersectionDirective from './frontoffice/directives/v-intersection';
import { createMetaManager } from 'vue-meta';
import 'animate.css';
import en from './locales/en.json';
import fr from './locales/fr.json';
import ar from './locales/ar.json';
import AOS from 'aos'
import { MotionPlugin } from '@vueuse/motion'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { createHead } from '@vueuse/head';
const head = createHead();

// Create a Vue Meta Manager instance
const metaManager = createMetaManager();

// Initialize Vue I18n
const i18n = createI18n({
  locale: 'fr', 
  fallbackLocale: 'fr', 
  messages: {
    en,
    fr,
    ar,
  },
});

// Function to handle language direction
const setLanguageDirection = (lang) => {
  if (lang === 'ar') {
    document.documentElement.setAttribute('dir', 'rtl');
  } else {
    document.documentElement.setAttribute('dir', 'ltr');
  }
};

// Set initial language direction
setLanguageDirection(i18n.global.locale);

// Create and mount the Vue app
createApp(App)
  .directive('intersection', IntersectionDirective)
  .use(Toast, {
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
  })
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_MAP_API,
      libraries: 'places, directions',
    },
  })
  .component('Datepicker', VueDatepickerUi)
  .use(router)
  .use(store)
  .use(i18n)
  .use(metaManager)
  .use(head)
  .use(AOS)
  .use(MotionPlugin)
  .mount('#app');

// Watch for language changes
router.beforeEach((to, from, next) => {
  // Extract the language from the route or use the localStorage value or default to 'fr'
  const lang = to.params.lang || localStorage.getItem('lang') || 'fr';

  // Validate the language and redirect to the default if invalid
  if (!['en', 'fr', 'ar'].includes(lang)) {
    return next('/fr');
  }

  // Update the localStorage value if the route language changes
  if (localStorage.getItem('lang') !== lang) {
    localStorage.setItem('lang', lang);
  }

  // Update the i18n locale if it differs from the route language
  if (i18n.global.locale !== lang) {
    i18n.global.locale = lang;
    setLanguageDirection(lang); // Set RTL or LTR based on language
  }

  next();
});
