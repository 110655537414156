<template>
      <div v-if="isMobile" class="swiper-container">
          <swiper
            :spaceBetween="30"
            :pagination="{ clickable: true }"
            :autoplay="{ delay: 3000, disableOnInteraction: false }"
            :modules="modules"
            class="mySwiper"
          >
          <swiper-slide
              v-for="(article, index) in this.articles" 
              :key="index"
            >
            <ArticleElement :article="article" />
        </swiper-slide> 
          </swiper>
          <div class="swiper-pagination"></div>
  
        </div>
    
        <div v-else >
          <swiper
            :spaceBetween="10"
            :pagination="{ clickable: true, el: '.swiper-pagination', type: 'bullets' }"
            :breakpoints="{
              '1024': { slidesPerView: 4, spaceBetween: 8 }
            }"
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide
              v-for="(article, index) in this.articles" 
              :key="index"
            >
            <ArticleElement :article="article" />
        </swiper-slide>
          </swiper>
        </div>
      <a href="https://tunisiagotravel.com/blog" target="_blank" class="text-blue underline font-bold text-sm flex justify-center">{{ $t('seeAll') }}</a>
    </template>
  
  <script>
  import ArticleElement from './ArticleElement.vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/pagination';
  import { Pagination } from 'swiper/modules';
  
  export default {
      components: {
          ArticleElement,
          Swiper,
          SwiperSlide
      },
      props: {
      articles: {
        type: Array,
        required: true,
      },
    },
      data() {
          return {
              isMobile: false
          };
      },
      setup() {
        return {
          modules: [Pagination],
        };
      },
      methods: {
          handleResize() {
              this.isMobile = window.innerWidth <= 768;
          },
      },
      async mounted() {
          this.handleResize();
          window.addEventListener('resize', this.handleResize);
      },
      beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
      },
  
  };
  </script>
  <style scoped>
  .mySwiper {
      width: 100%;
      padding: 8px;
      height: 20rem;
  }
  </style>